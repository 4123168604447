import { instance, http_file } from '@/utils/http.js'

/**
 *  获取试卷地区
 */
export const getAllPaperDis = function () {
  return instance({
    url: '/api/v1/public/select_district',
    method: 'get'
  })
}