import { instance } from '@/utils/http.js'

// 根据科目获取年级
export const getGradeBysubject = function (params) {
  return instance({
    url: '/api/v1/resource/subject_to_grade',
    method: 'get',
    params
  })
}

/**
 *  获取年级
 */
export const getGrade = function () {
  return instance({
    url: '/api/v1/school/select_grade',
    method: 'get'
  })
}