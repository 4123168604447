import { getGrade } from '@/api/grade.js'
import { getAllPaperDis } from '@/api/dis.js'
import { getNote } from '@/api/note'
export default {
  mounted () {
    this.getSubjectList()
    document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    this.initList()
    if (this.$route.query.form) {
      this.form = JSON.parse(this.$route.query.form)
      this.delTopic()
    }
  },
  methods: {
    async initList () {
      const { data } = await getGrade()
      this.gradeList = data.list
      const { data: disData } = await getAllPaperDis()
      this.disList = disData
    },
    getCapabilitySubject () {
      this.$http({
        url: '/api/v1/question/capabilities',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          capability_type_id: 2 //学科素养
        }
      }).then(res => {

        this.capabilitySubject = res.data.list
      })
    },
    getInfo (top) {
      this.getCapabilitySubject()
      this.$refs.formRef.validate(val => {
        if (!val) {
          this.info = []
          return
        }
        this.$http({
          url: '/api/v1/question/proofread',
          method: 'post',
          data: this.form
        }).then(res => {
          this.info = res.data.list
          this.info.map(item => {
            item.capabilitySubject = this.capabilitySubject
            const capabilityData = item.capability
            const capabilityType1 = [...new Set(capabilityData.flat(Infinity))]
            item.capability = capabilityType1;

          })
          console.log('this.info', this.info)
          this.nums = res.data.paper_score
          this.paperChecked = res.data.paperChecked
          this.$nextTick(() => {
            var oFixed = document.getElementsByClassName('yuan')
            this.childredList = oFixed.length
            document.getElementsByClassName('view_warp')[0].scrollTop = top
          })
          this.getPaperNote()
        })
      })
    },
    async getPaperNote () {
      let form = {
        source_id: this.form.paperSource,
        year: this.form.paperYear,
      }
      const { data } = await getNote(form)
      this.note = data.note
    },

    async showInput () {
      let form = {
        source_id: this.form.paperSource,
        year: this.form.paperYear,
        note: this.note
      }
      this.$refs.RemarkRef.showNote = true
      this.$refs.RemarkRef.form = form
      this.$refs.RemarkRef.dialogVisible = true

    },
    getSourceList (num = 0) {
      this.getCapabilitySubject()
      this.info = []
      if (!num) {
        this.form.paperSource = ''
      }
      if (!this.form.subject_id) {
        return
      }
      this.paperSourceList = []
      this.$http({
        url: '/api/v1/public/source_lst',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          district_id: this.form.dis_id,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        this.paperSourceList = res.data
      })
    },
    getSubjectList () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        res.data.subject.splice(0, 1)
        this.subjectList = res.data.subject
      })
    },
    over () {
      this.$http({
        url: '/api/v1/question/submit_proofread',
        method: 'post',
        data: {
          paperSource: this.form.paperSource,
          paperYear: this.form.paperYear
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '校对成功!',
          type: 'success'
        });
        this.info = []
        this.childredList = 0
        this.paperChecked = 10
      })
    },
    delTopic () {
      this.$nextTick(() => {
        let scrollTop = window.sessionStorage.getItem('scrollTop') || ''
        this.getInfo(scrollTop)
      })
    }

  }
}