
import { instance } from '@/utils/http.js'

// 获取试卷备注
export const getNote = function (params) {
  return instance({
    url: '/api/v1/question/source_note',
    method: 'get',
    params
  })
}


// 获取试卷备注
export const saveNote = function (data) {
  return instance({
    url: '/api/v1/question/save_source_note',
    method: 'post',
    data
  })
}
